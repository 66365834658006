import OndcRoutes from "./Router/Router";
import {ThemeProvider} from "@mui/material/styles";
import "./Api/firebase-init";
import theme from "./theme";

function App() {
  return (
    <ThemeProvider theme={theme}>
    <div className='App'>
      <OndcRoutes />
    </div>
    </ThemeProvider>
  );
}

export default App;
