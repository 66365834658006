//! This file is not being used as of now
import React from "react";
import { useTheme, styled } from "@mui/material/styles";
import { PRODUCT_CATEGORY } from "../../../utils/constants";
import { Link } from "react-router-dom";

const StyledContainer = styled('div')(({theme})=>({
  borderColor: theme.palette.secondary.main,
  color: theme.palette.primary.contrastText,
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const MenuCategory = () => {
  const theme = useTheme();
  return (
    <div className="container mx-auto my-8">
      <div className="mb-4 flex flex-row justify-between items-center">
        <label style={{ color: theme.palette.text.primary }} className="font-semibold text-2xl">
          Available Categories:
        </label>
      </div>
      <div className="flex flex-wrap">
        {Object.entries(PRODUCT_CATEGORY).map(([key]) => (
          <Link to={`/application/menu-category/${key}`} key={key}>
            <MenuItem name={key} />
          </Link>
        ))}
      </div>
    </div>
  );
};

const MenuItem = (props) => {
  const { name } = props;
  return (
    <StyledContainer className={`flex items-center justify-center cursor-pointer mr-4 mb-4 border-2 rounded-2xl w-56 h-28 bg-[#ffffff] duration-300`}
    >
      <p className="text-base font-semibold text-center">{name}</p>
    </StyledContainer>
  );
};

export default MenuCategory;
