import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#ebe4dc',
      contrastText: '#000'
    },
    secondary: {
      main: '#d4b48e'
    },
    tertiary: {
      main: '#b47437'
    },
    text: {
      primary: "#000"
    }
  }
});

export default theme;