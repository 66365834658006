import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import RenderInput from "../../../utils/RenderInput";
import { getCall } from "../../../Api/axios";
import BackNavigationButton from "../../Shared/BackNavigationButton";

const fields = {
  provider: [
    {
      id: "name",
      title: "Name",
      placeholder: "Enter your Name",
      type: "input",
      required: true,
    },
    {
      id: "email",
      title: "Email",
      placeholder: "Enter your Email Address",
      type: "input",
      required: true,
    },
    {
      id: "mobile",
      title: "Mobile Number",
      placeholder: "Enter your Mobile Number",
      type: "input",
      required: true,
    },
  ],
  kyc: [
    {
      id: "gst_no",
      title: "GSTIN Certificate",
      placeholder: "GSTIN Certificate",
      type: "input",
      required: true,
    },
    {
      id: "gst_proof",
      title: "GSTIN Proof",
      type: "upload",
      file_type: "gst",
      required: true,
      fontColor: "#ffffff",
    },
    {
      id: "pan_no",
      title: "Aadhar Number",
      placeholder: "Enter your AADHAR",
      type: "input",
      required: true,
    },
    {
      id: "id_proof",
      title: "ID Proof",
      type: "upload",
      fontColor: "#ffffff",
      file_type: "id_proof",
      required: true,
    },
  ],
  bank: [
    {
      id: "bankName",
      title: "Bank Name",
      placeholder: "Enter Bank Name",
      type: "input",
      required: true,
    },
    {
      id: "branchName",
      title: "Branch Name",
      placeholder: "Enter Branch Name",
      type: "input",
      required: true,
    },
    {
      id: "IFSC",
      title: "IFSC Code",
      placeholder: "Enter IFSC Code",
      type: "input",
      required: true,
    },
    {
      id: "accHolderName",
      title: "Account Holder Name",
      placeholder: "Enter Account Holder Name",
      type: "input",
      required: true,
    },
    {
      id: "accNumber",
      title: "Account Number",
      placeholder: "Enter Account Number",
      type: "input",
      required: true,
    },
    {
      id: "cancelledCheque",
      title: "Upload Bank Passbook / Cancelled Cheque (Business Name / Trade Name should be clearly visible)",// "Bank Passbook/Cancelled Cheque",
      type: "upload",
      fontColor: "#ffffff",
      file_type: "cancelled_check",
      required: true,
    },
  ],
};

const ProfileDetails = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [details, setDetails] = useState({
    provider: {},
    kyc: {},
    bank: {},
  });

  const getOrgDetails = async (id) => {
    try {
      const url = `/api/v1/organizations/${id}`;
      const res = await getCall(url);

      setDetails({
        provider: {
          email: res.user.email,
          mobile: res.user.mobile,
          name: res.user.name,
        },
        kyc: {
          gst_no: res?.providerDetail?.GSTN?.GSTN,
          gst_proof: constructProofUrl(res?.providerDetail?.GSTN?.proof?.path),
          pan_no: res?.providerDetail?.PAN?.PAN,
          id_proof: constructProofUrl(res?.providerDetail?.idProof?.path),
        },
        bank: {
          bankName: res?.providerDetail?.bankDetails?.bankName,
          branchName: res?.providerDetail?.bankDetails?.branchName,
          IFSC: res?.providerDetail?.bankDetails?.IFSC,
          accHolderName: res?.providerDetail?.bankDetails?.accHolderName,
          accNumber: res?.providerDetail?.bankDetails?.accNumber,
          cancelledCheque: constructProofUrl(
            res?.providerDetail?.bankDetails?.cancelledCheque?.path
          ),
        },
      });

      function constructProofUrl(originalUrl) {
        if (!originalUrl) {
          return undefined;
        }
        let myBucket = process.env.REACT_APP_S3_BUCKET;
        let regionString = '-' + process.env.REACT_APP_S3_REGION;
        let url = `https://${myBucket}.s3${regionString}.amazonaws.com`;
        const baseUrl = url;
        const modifiedImagePath = originalUrl.replace(
          new RegExp(`^${baseUrl}/`),
          ""
        );
        const modifiedUrl = modifiedImagePath
          .replace(/\+/g, "%2B")
          .replace(/ /g, "+");
        return `${baseUrl}/${modifiedUrl}`;
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let provider_id = params?.id;
    getOrgDetails(provider_id);
  }, [params.id]);

  const renderFields = (fieldGroup) => (
    <>
      {fields[fieldGroup].map((item) => (
        <RenderInput
          previewOnly={true}
          item={item}
          state={details[fieldGroup]}
          statehandler={(updatedState) =>
            setDetails((prevDetails) => ({
              ...prevDetails,
              [fieldGroup]: updatedState,
            }))
          }
          key={item.id}
        />
      ))}
    </>
  );

  return (
    <div className="container mx-auto my-8">
      <div
        className="w-full bg-white px-4 py-4 rounded-md h-full scrollbar-hidden"
        style={{ minHeight: "95%", maxHeight: "100%", overflow: "auto" }}
      >
        <div className="m-auto w-10/12 md:w-3/4 h-max">
          <BackNavigationButton
            onClick={() => {
              navigate("/application/inventory");
            }}
          />
          <p className="text-2xl font-semibold mb-4">Provider Details</p>
          {renderFields("provider")}
          <p className="text-2xl font-semibold mb-4 mt-14">KYC Details</p>
          {renderFields("kyc")}
          <p className="text-2xl font-semibold mb-4 mt-14">Bank Details</p>
          {renderFields("bank")}
        </div>
      </div>
    </div>
  );
};

export default ProfileDetails;
