import { useEffect, useState, useMemo } from "react";
import cogoToast from "cogo-toast";
import axios from "axios";
import moment from "moment";
import MyButton from "../../Shared/Button";
import { useLocation, useNavigate } from "react-router-dom";
import useCancellablePromise from "../../../Api/cancelRequest";
import { getCall, postCall, putCall } from "../../../Api/axios";
import useForm from "../../../hooks/useForm";
import { getEnvVariableValue } from '../../../utils/utilityFunctions';
import {
  PRODUCT_SUBCATEGORY,
  FIELD_NOT_ALLOWED_BASED_ON_PROTOCOL_KEY,
  MAX_STRING_LENGTH,
  MAX_STRING_LENGTH_50,
  MAX_STRING_LENGTH_100,
  MAX_STRING_LENGTH_14,
  MAX_STRING_LENGTH_3,
  MAX_STRING_LENGTH_6,
  MAX_STRING_LENGTH_10,
  MAX_STRING_LENGTH_13,
  MAX_STRING_LENGTH_8,
  MAX_STRING_LENGTH_12,
} from "../../../utils/constants";
import { isAmountValid, isNumberOnly } from "../../../utils/validations";
import {
  allProductFieldDetails,
  categoryFields,
  productDetailsFields,
  variationCommonFields,
  UOMVariationFields,
  ecomFields,
  _deliveryFields,
} from "./product-fields-modified";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import AddVariants from "./AddVariants";
import { allProperties } from "./categoryProperties";
import AddProductInfo from "./AddProductInfo";
import { getFormErrors } from "./utils";
import VitalForm from "./VitalForm";
import { v4 as uuidv4 } from "uuid";
import CustomizationRenderer from "./CustomizationRenderer";
import { CircularProgress } from "@mui/material";
import { _deliveryFieldsDetails, ecomFieldsDetails } from "./product-fields-modified";

const AddGenericProduct = ({
  state,
  category,
  subCategory,
  categoryForm,
  selectedVariantNames,
  variants,
  attributes,
  variationOn,
  jewelleryType,
  jewelleryCategory,
}) => {
  const navigate = useNavigate();
  const hasVariants = selectedVariantNames.length > 0;
  const [allFields, setAllFields] = useState(allProductFieldDetails);
  const [focusedField, setFocusedField] = useState("");
  const { cancellablePromise } = useCancellablePromise();
  const [productInfoFields, setProductInfoFields] = useState([]);

  const [variantFields, setVariantFields] = useState([]);
  const [variantInitialValues, setVariantInitialValues] = useState({});
  const [variantForms, setVariantForms] = useState([]);
  const [variantFormsErrors, setVariantFormsErrors] = useState([]);

  const [vitalForm, setVitalForm] = useState({});
  const [vitalFormErrors, setVitalFormErrors] = useState({});
  const [vitalFields, setVitalFields] = useState([]);


  const [deliveryForm, setDeliveryForm] = useState({
    dispatchTime: "2",
    returnWindow: "7",
  });
  const [deliveryFormErrors, setDeliveryFormErrors] = useState({});
  const [deliveryFields, setDeliveryFields] = useState(_deliveryFieldsDetails);

  const [tabErrors, setTabErrors] = useState([true, true, true, true]);
  const [formValidate, setFormValidate] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  // const[GoldRate,setGoldRate]= useState(0);

  const [customizationGroups, setCustomizationGroups] = useState([]);
  const [customizations, setCustomizations] = useState([]);

  const [tabValue, setTabValue] = useState("1");

  const initialValues = {
    productCode: "",
    productName: "",
    GST_Percentage: 3,
    quantity: 1,
    QCStatus: "InProgress",
    QCDesc: "In Progress",
    // huidNumber: "",
    huidDone: "",

    Finishing: "",

    weight: "",
    wastageOnGold: "",
    net_weight: "",
    diamonds_weight: "",
    diamondColour: "",
    diamondClarity: "",
    Purity: "",

    totalSellingPrice: "",
    sellerPrice: "",

    diamondCostPerCt: "",
    makingCharges: "",

    description: "",

    images: [],
    certifications: [],

    gemstones: [],
    gemstonePrice: "",

    // fulfillmentOption: "",
    // dispatchTime: "2",
    // isCancellable: "false",
    // isReturnable: "false",
    // returnWindow: "7",
    // countryOfOrigin: "IND",
    // manufacturerOrPackerName: "",
    // manufacturerOrPackerAddress: "",
    // HSNCode: "7113",
  };

  const productInfoForm = useForm({
    ...initialValues,
  });

  const { formValues, setFormValues, errors, setErrors } = productInfoForm;

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const formatAttributesToFieldsDataFormat = (variants, required = false) => {
    return variants.map((variant) => {
      return {
        id: variant.name,
        title: variant.name,
        placeholder: "Example, " + variant.example,
        type: variant.type || "input",
        required: required,
        options: variant.type === "select" ? variant.options : null,
        file_type: variant.type === "upload" ? "product_image" : null,
      };
    });
  };

  const formattedVariantDataForAddProduct = () => {
    let variant_forms_data = [...variantForms];

    return variant_forms_data.map((variantData) => {
      if (variationOn === "attributes") {
        let variant_attrs = selectedVariantNames.reduce((acc, variant_name) => {
          acc[variant_name] = variantData[variant_name];
          delete variantData[variant_name];
          return acc;
        }, {});

        variantData["varientAttributes"] = variant_attrs;
      }
      delete variantData["formKey"];
      delete variantData["uploaded_urls"];

      return variantData;
    });
  };
  const calculateGemstonePrice = (updatedValues) => {
    return updatedValues.gemstones.reduce((total, gemstone) => {
      return (
        total +
        parseInt(gemstone.gemstoneCostPerStone) *
        parseInt(gemstone.gemstoneCount)
      );
    }, 0);
  };
  useEffect(() => {
    const fetchGoldRates = async () => {
      try {
        const response = await axios.get(
          `https://${getEnvVariableValue('REACT_APP_BPP_ID')}/protocol/v1/goldprice`
          // "https://aurikatech.com/protocol/v1/goldprice"
        );

        console.log("Here is the useEffect goldRate state fetched");
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching gold rates:", error.message);
      }
    };
  }, []);

  useEffect(() => {
    if (state) {
      return;
    } else {
      const user = JSON.parse(localStorage.getItem("user"));
      getCall(`/api/v1/organizations/${user.organization}/storeDetails`).then(
        (resp) => {
          console.log("Response of store details : ", resp)
          if (resp && resp.storeDetails && resp.storeDetails.address) {
            const address = resp?.storeDetails?.address;
            const fulfillmentsArray = resp?.storeDetails?.fulfillments;
            let fulfillments = "delivery";

            if (fulfillmentsArray && fulfillmentsArray.length == 0) {
              fulfillments = "delivery";
            } else if (fulfillmentsArray[0].type === "delivery&pickup") {
              fulfillments = "delivery";
            } else {
              fulfillments = fulfillmentsArray[0].type;
            }

            const fullAddress = `${address.building}, ${address.locality}, ${address.city}, ${address.state}, ${address.country} - ${address.area_code}`;
            // setFormValues((prevValues) => ({
            //   ...prevValues,
            //   manufacturerOrPackerAddress: fullAddress,
            // }));
            setDeliveryForm((prevValues) => ({
              ...prevValues,
              fulfillmentOption: fulfillments,
              isCancellable: resp.storeDetails.defaultCancellable ? "true" : "false",
              isReturnable: resp.storeDetails.defaultReturnable ? "true" : "false",
            }));
            setVitalForm((prev) => ({
              ...prev,
              HSNCode: "7113",
              countryOfOrigin: "IND",
              manufacturerOrPackerAddress: fullAddress,
            }))
          }
        }
      );
    }
  }, []);
  useEffect(() => {
    if (state) {
      return;
    } else {
      const user = JSON.parse(localStorage.getItem("user"));
      getCall(`/api/v1/organizations/${user.organization}`).then((resp) => {
        if (resp) {
          const storeName = resp?.providerDetail.name;
          // setFormValues((prevValues) => ({
          //   ...prevValues,
          //   manufacturerOrPackerName: storeName,
          // }));
          setVitalForm((prevValues) => ({
            ...prevValues,
            manufacturerOrPackerName: storeName,

          }));
        }
      });
    }
  }, []);
  const [goldRate, setGoldRate] = useState(0);
  const [goldRateFetched, setGoldRateFetched] = useState(false);
  const [city, setCity] = useState("Delhi");

  // Function to normalize city names
  const normalizeCityName = (city) => {
    return city
      .toLowerCase()
      .replace(/^\w|\s\w/g, (char) => char.toUpperCase());
  };
  let cityName = "";
  useEffect(() => {
    let isMounted = true;

    const fetchGoldPrice = async () => {
      try {
        const user = JSON.parse(localStorage.getItem("user"));
        let orgDetailsPromise;

        if (state) {
          console.log("Here is the if loop");
          console.log("Here is the state.productId", state.productId);
          console.log("Here is the first API endpoint");
          console.log(`/api/v1/products/GetDataForQC/${state.productId}`);

          orgDetailsPromise = state.productId
            ? getCall(`/api/v1/products/GetDataForQC/${state.productId}`).then(
              (resp) => {
                console.log("HERE2 --->>> resp", resp);
                console.log("Here is the second API endpoint");
                console.log(`/api/v1/organizations/${resp.commonDetails.organization}`);
                return getCall(
                  `/api/v1/organizations/${resp.commonDetails.organization}`
                ).then((resp) => {
                  console.log(
                    "Final organization details received:",
                    resp
                  );
                  return resp;
                });
              }
            )
            : getOrgDetails(user.organization);
          console.log("HERE6 --->>> orgDetailsPromise", orgDetailsPromise);
        } else {
          orgDetailsPromise = getCall(
            `/api/v1/organizations/${user.organization}`
          );
          console.log("HERE7 --->>> orgDetailsPromise", orgDetailsPromise);
        }

        const res = await orgDetailsPromise;
        console.log("HERE1 --->>> res", res);
        if (!isMounted) return;

        const cityName = (
          res.providerDetail.storeDetails.address.city || "Nellore"
        ).toLowerCase();
        console.log("Here is the City", cityName);

        setCity(cityName);

        if (goldRateFetched) return;

        let goldRatesResponse = await getCall(`/api/v1/goldrates/${cityName}`);

        let cityPrice = goldRatesResponse.data.TWENTY_FOUR;

        setGoldRate(cityPrice);
        console.log("Here is the city price", cityPrice);
        setGoldRateFetched(true);
        console.log("Gold Rate Fetched", cityPrice);

        cogoToast.success("Gold Rate Fetched");
        return cityPrice;
      } catch (error) {
        if (!isMounted || goldRateFetched) return;

        cogoToast.warn("Error fetching gold rate try after some time");
        console.error("Error fetching gold rate try after some time", error);

        return error;
      }
    };

    fetchGoldPrice();

    return () => {
      isMounted = false;
    };
  }, [state, goldRateFetched]);

  if (state) {
    console.log("State", state);
  }
  const calculateTotalSellingPrice = (updatedValues, goldRate) => {
    const diamondCostPerCt = parseFloat(updatedValues.diamondCostPerCt) || 0;
    const makingCharges = parseFloat(updatedValues.makingCharges) || 0;
    const gemstonePrice = parseFloat(updatedValues.gemstonePrice) || 0;
    const diamonds_weight = parseFloat(updatedValues.diamonds_weight) || 0;
    const wastageOnGold = parseFloat(updatedValues.wastageOnGold) || 0;
    const netWeight = parseFloat(updatedValues.net_weight) || 0;
    const Purity = updatedValues.Purity || 22;
    const GoldPrice = (goldRate * Purity) / 24;
    const updatedTotalSellingPrice =
      (netWeight * (1 + (wastageOnGold + 2) / 100) * GoldPrice +
        diamondCostPerCt * diamonds_weight +
        makingCharges +
        gemstonePrice) *
      (1 + parseFloat(updatedValues.GST_Percentage) / 100);

    return updatedTotalSellingPrice;
  };
  const calculateSellerPrice = (updatedValues, goldRate) => {
    const diamondCostPerCt = parseFloat(updatedValues.diamondCostPerCt) || 0;
    const makingCharges = parseFloat(updatedValues.makingCharges) || 0;
    const gemstonePrice = parseFloat(updatedValues.gemstonePrice) || 0;
    const diamonds_weight = parseFloat(updatedValues.diamonds_weight) || 0;
    const wastageOnGold = parseFloat(updatedValues.wastageOnGold) || 0;
    const netWeight = parseFloat(updatedValues.net_weight) || 0;
    const Purity = updatedValues.Purity || 22;
    const GoldPrice = (goldRate * Purity) / 24;
    const updatedSellerPrice =
      (netWeight * (1 + wastageOnGold / 100) * GoldPrice +
        diamondCostPerCt * diamonds_weight +
        makingCharges +
        gemstonePrice) *
      (1 + parseFloat(updatedValues.GST_Percentage) / 100);
    return updatedSellerPrice;
  };
  const gemstonePrice = useMemo(
    () => calculateGemstonePrice(formValues),
    [formValues]
  );
  const totalSellingPrice = useMemo(
    () => calculateTotalSellingPrice(formValues, goldRate),
    [formValues, goldRate]
  );
  const sellerPrice = useMemo(
    () => calculateSellerPrice(formValues, goldRate),
    [formValues, goldRate]
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setFormValues((prevValues) => ({
        ...prevValues,
        gemstonePrice: gemstonePrice.toFixed(2),
        totalSellingPrice: totalSellingPrice.toFixed(2),
        sellerPrice: sellerPrice.toFixed(2),
      }));
    }, 2000);

    return () => clearInterval(intervalId);
  }, [gemstonePrice, totalSellingPrice, sellerPrice]);

  const addProduct = async () => {
    console.log("At add product");
    try {
      let product_data = Object.assign({}, formValues, categoryForm.formValues);
      let vital_data = Object.assign({}, vitalForm);
      let delivery_data = Object.assign({}, deliveryForm);

      let ecom_data = {}
      ecomFields.forEach((e) => {
        ecom_data[e] = vital_data[e];
        delete vital_data[e];
      })

      console.log("product_data :", product_data, " delivery_data :", delivery_data, " vital_data :", vital_data, " ecom_data :", ecom_data);
      product_data = { ...product_data, ...delivery_data, ...ecom_data };


      let variant_data = formattedVariantDataForAddProduct();
      let api_url = "/api/v1/SellerProducts";


      const subCatList =
        PRODUCT_SUBCATEGORY[categoryForm.formValues?.productCategory];
      const selectedSubCatObject = subCatList.find(
        (subitem) =>
          subitem.value === categoryForm.formValues?.productSubcategory1
      );
      if (selectedSubCatObject && selectedSubCatObject.protocolKey) {
        const hiddenFields =
          FIELD_NOT_ALLOWED_BASED_ON_PROTOCOL_KEY[
          selectedSubCatObject.protocolKey
          ];
        hiddenFields.forEach((field) => {
          delete product_data[field];
        });
      } else {
      }
      if (variationOn !== "none") {
        variationCommonFields.forEach((field) => {
          delete product_data[field];
        });
      }

      // Create a duration object with the hours you want to convert
      const duration = moment.duration(parseInt(product_data.returnWindow), "days");
      // Format the duration in ISO 8601 format
      const iso8601 = duration.toISOString();
      product_data.returnWindow = iso8601;

      product_data.huidDone =
        product_data.huidDone === "true" ? true : false;
      product_data.isCancellable =
        product_data.isCancellable === "true" ? true : false;
      product_data.isReturnable =
        product_data.isReturnable === "true" ? true : false;
      // product_data.isVegetarian = product_data.isVegetarian === "true" ? true : false;
      product_data.availableOnCod =
        product_data.availableOnCod === "true" ? true : false;
      product_data.productSubcategory2 = jewelleryType;
      product_data.productSubcategory3 = jewelleryCategory;
      delete product_data["uploaded_urls"];
      delete vital_data["tempURL"];


      let data = {
        commonDetails: product_data,  // pri + del
        commonAttributesValues: vital_data, // vital + ecom
      };
      
      if (variationOn !== "none") {
        data["variantSpecificDetails"] = variant_data;
        data["variationOn"] = variationOn?.toUpperCase();
      }
      
      if (variationOn !== "none") {
        api_url = "/api/v1/productWithVariant"
        data = prodWithVarDataManupulation(data);
      }
      console.log("This is api and data on add btn click : ",api_url, data);
      await cancellablePromise(postCall(api_url, data));
      cogoToast.success("Product added successfully!");
      navigate("/application/inventory");
    } catch (error) {
      console.log("Addbtn error : " ,error);
   
    }
  };

  const prodWithVarDataManupulation = (resp) => {
    let data = {
      commonDetails: resp.commonDetails,
      commonAttributesValues: resp.commonAttributesValues,
      variantSpecificDetails: resp.variantSpecificDetails,
      variationOn: resp.variationOn,
    };
    // if (resp.commonDetails.QCStatus === "Approved") {
    //   cogoToast.success("The product is Already Approved");
    //   return;
    // }
    console.log("Here is the Data");
    console.dir(data,{depth:null});
    const fieldMapping = {
      totalSellingPrice: "MRP",
      sellerPrice:"sellerPrice",
      sellerPrice:"purchasePrice",
      10:"maxAllowedQty",
      _id:"relation",
    };
    console.log("Here is the fieldMapping",fieldMapping);
    const fieldsToRemove = [
      "_id",
      "__v",
      "published",
      "updatedAt",
      "QCStatus",
      "QCDesc",
      "huidNumber",
      "huidDone",
      "Finishing",
      "wastageOnGold",
      "net_weight",
      "diamonds_weight",
      "diamondColour",
      "diamondClarity",
      "diamondPrice",
      "Purity",
      "diamondCostPerCt",
      "makingCharges",
      "certifications",
      "gemstones",
      "gemstonePrice",
      "productSubcategory2",
      "productSubcategory3",
      // for variants
      "images",
      "quantity",
      "jewelleryCategory",
      "jewelleryType",
      "purchasePrice",
      "MRP",
    ];
    // const id = data.commonDetails._id;
    Object.keys(fieldMapping).forEach((oldField) => {
      const newField = fieldMapping[oldField];
      if (data.commonDetails.hasOwnProperty(oldField)) {
        data.commonDetails[newField] = data.commonDetails[oldField];
        delete data.commonDetails[oldField];
      }
    });
    console.log("Here is the data before removing");
    console.dir(data,{depth:null});
    fieldsToRemove.forEach((field) => {
      delete data.commonDetails[field];
    });
    console.log("Here is the data after removing");
    console.dir(data,{depth:null});

    // Step 3: Send manipulated data to the second API
    // let data2 = {
    //   QCStatus: "Approved",
    //   QCDesc: "Approved",
    // };
    // postCall(`/api/v1/products?_id=${id}`, data).then((resp) => {
    //   cogoToast.success("Product Pushed to Main DB!");
    //   putCall(`/api/v1/products/${product_id}/QC`, data2).then((resp) => {
    //     cogoToast.success("Product QC Status Updated");
    //     getProducts();
    //   });
    // });
    return data;
  }

  function constructProofUrl(originalUrl) {
    if (!originalUrl) {
      return undefined;
    }
    console.log(originalUrl);
    let myBucket = process.env.REACT_APP_S3_BUCKET;
    let regionString = '-' + process.env.REACT_APP_S3_REGION;
    const baseUrl = `https://${myBucket}.s3${regionString}.amazonaws.com`;
    const replacementString = "/public-assets/";

    let modifiedImagePath = originalUrl.replace(new RegExp(`^${baseUrl}/`), "");
    console.log("Modified Image Path", modifiedImagePath);

    let newlymodifiedImagePath = modifiedImagePath.replace(
      new RegExp(replacementString),
      "/"
    );
    console.log("WITHOUT PUBLIC ASSETS", newlymodifiedImagePath);

    const modifiedUrl = newlymodifiedImagePath
      .replace(/\+/g, "%2B")
      .replace(/ /g, "+");

    const newUrl = `${baseUrl}/${modifiedUrl}`;
    console.log("CONSTRUCT FUNCTION DONE WORKING");
    console.log(newUrl);
    return newUrl;
  }
  const getProduct = () => {
    getCall(`/api/v1/SellerProducts/${state.productId}`)
      .then((resp) => {
        console.log("Response of getProduct: ", resp);
        resp.commonDetails["uploaded_urls"] =
          resp?.commonDetails.images?.map((i) => i?.url) || [];
        resp.commonDetails["images"] =
          resp?.commonDetails.images?.map((i) => i?.path) || [];
        resp.commonDetails["certifications"] =
          resp?.commonDetails.certifications?.map((i) => i) || [];

        resp.commonDetails.huidDone = resp.commonDetails.huidDone
          ? "true"
          : "false";
        resp.commonDetails.isCancellable = resp.commonDetails.isCancellable
          ? "true"
          : "false";
        resp.commonDetails.isReturnable = resp.commonDetails.isReturnable
          ? "true"
          : "false";
        resp.commonDetails.isVegetarian = resp.commonDetails.isVegetarian
          ? "true"
          : "false";
        resp.commonDetails.availableOnCod = resp.commonDetails.availableOnCod
          ? "true"
          : "false";

        // console.log(resp.commonAttributesValues["size_chart"]);
        // resp.commonAttributesValues["size_chart"] = resp?.commonAttributesValues?.size_chart?.url;

        // console.log("commonDetails : ",resp.commonDetails);
        // console.log("commonAttributesValues : ",resp.commonAttributesValues);



        const duration = moment.duration(resp.returnWindow);
        const days = duration.asDays();
        const returnWindow = resp.commonDetails.returnWindow;
        const returnWindowPattern = /P(\d+)D/;
        const durationMatch = returnWindow.match(returnWindowPattern);
        let finalReturnWindow = 0;
        if (durationMatch) {
          finalReturnWindow = parseInt(durationMatch[1]);
        }
        console.log("Here is the return Window :", finalReturnWindow);
        resp.commonDetails.returnWindow = finalReturnWindow;

        if (resp.commonAttributesValues["size_chart"]) {
          resp.commonAttributesValues["size_chart"] =
            resp.commonAttributesValues["size_chart"].url;
        }

        let ecom_data = {};
        ecomFields.forEach((e) => {
          ecom_data[e] = resp.commonDetails[e];
          delete resp.commonDetails[e];
        });
        let delivery_data = {};
        _deliveryFields.forEach((e) => {
          delivery_data[e] = resp.commonDetails[e];
          delete resp.commonDetails[e];
        });


        setVitalForm({ ...ecom_data, ...resp.commonAttributesValues });

        setFormValues({ ...resp.commonDetails });
        setDeliveryForm({ ...delivery_data });

        let category = resp.commonDetails["productCategory"];
        let sub_category = resp.commonDetails["productSubcategory1"];


        let attributes =
          allProperties[category][sub_category] ||
          allProperties[category]["default"];

        let ecomFieldDetails2 = ecomFields.map((field_id) =>
          getProductFieldDetails(field_id)
        );
        let deliveryfieldDetails2 = _deliveryFields.map((field_id) =>
          getProductFieldDetails(field_id)
        );

        setVitalFields([...ecomFieldsDetails, ...formatAttributesToFieldsDataFormat(attributes)]);

        if (resp.customizationDetails) {
          let customization_groups =
            resp.customizationDetails.customizationGroups.map((group) => {
              let optional = group.minQuantity === 0 ? true : false;
              return { ...group, optional: optional };
            });
          console.log("here are customization_groups:", customization_groups);

          setCustomizationGroups(customization_groups);
          setCustomizations(resp.customizationDetails.customizations);
        }

      })
      .catch((error) => {
        console.log(error);
      });
  };
  const updateProduct = async () => {
    console.log("At update Btn.")
    try {
      let product_data = Object.assign({}, formValues);
      let vital_data = Object.assign({}, vitalForm);
      let delivery_data = Object.assign({}, deliveryForm)

      let ecom_data = {};
      ecomFields.forEach((e) => {
        ecom_data[e] = vital_data[e];
        delete vital_data[e];
      });
      product_data = { ...product_data, ...delivery_data, ...ecom_data };

      const subCatList = PRODUCT_SUBCATEGORY[formValues?.productCategory];
      const selectedSubCatObject = subCatList.find(
        (subitem) => subitem.value === formValues?.productSubcategory1
      );
      if (selectedSubCatObject && selectedSubCatObject.protocolKey) {
        const hiddenFields =
          FIELD_NOT_ALLOWED_BASED_ON_PROTOCOL_KEY[
          selectedSubCatObject.protocolKey
          ];
        hiddenFields.forEach((field) => {
          delete product_data[field];
        });
      } else {
      }

      const duration = moment.duration(parseInt(product_data.returnWindow), "days");
      const iso8601 = duration.toISOString();
      product_data.returnWindow = iso8601;

      product_data.huidDone =
        product_data.huidDone === "true" ? true : false;
      product_data.isCancellable =
        product_data.isCancellable === "true" ? true : false;
      product_data.isReturnable =
        product_data.isReturnable === "true" ? true : false;
      // product_data.isVegetarian = product_data.isVegetarian === "true" ? true : false;
      product_data.availableOnCod =
        product_data.availableOnCod === "true" ? true : false;

      let fields_to_remove = [
        "__v",
        // "organization",
        "createdAt",
        "updatedAt",
        "published",
        "uploaded_urls",
        "createdBy",
        "_id",
        "variantGroup",
      ];

      fields_to_remove.forEach((field) => {
        delete product_data[field];
      });

      delete vital_data["tempURL"];

      let data = {
        commonDetails: product_data,
        commonAttributesValues: vital_data,
        // customizationDetails: {
        //   customizationGroups,
        //   customizations,
        // },
      };
      console.log("Update btn data:", data);

      await putCall(`/api/v1/SellerProducts/${state.productId}`, data);
      cogoToast.success("Product updated successfully!");
      navigate("/application/inventory");
    } catch (error) {
      console.log(error);
    }
  };

  const getProductFieldDetails = (category_id) => {
    return allFields.find((field) => field.id === category_id);
  };

  const getOrgDetails = async (id) => {
    try {
      let url;
      let fulfillments;
      let res;
      if (state) {
        url = `/api/v1/SellerProducts/${state.productId}`;
        res = await getCall(url);
        console.log("HERE4 --->>> res", res);
      } else {
        url = `/api/v1/organizations/${id}`;
        res = await getCall(url);
        console.log("HERE5 --->>> res", res);
      }
      if (state) {
        let id = res.commonDetails.organization;
        console.log("Here is the id", id);
        url = `/api/v1/organizations/${id}`;
        let response = await getCall(url);
        console.log("HERE6 --->>> res", response);
        fulfillments = response.providerDetail.storeDetails.fulfillments;
        console.log("Here is the fulfillments", fulfillments);
      } else {
        fulfillments = res.providerDetail.storeDetails.fulfillments;
      }

      getFulfillmentOptions(fulfillments);
    } catch (error) { }
  };

  const getFulfillmentOptions = (fulfillments) => {
    const availableOptions = [];

    fulfillments.forEach((fulfillment) => {
      if (fulfillment.id === "f1") {
        availableOptions.push({ key: "Delivery", value: "delivery" });
      }
      if (fulfillment.id === "f2") {
        availableOptions.push({ key: "Self Pickup", value: "selfPickup" });
      }
      if (fulfillment.id === "f3") {
        availableOptions.push({
          key: "Delivery And Self Pickup",
          value: "deliveryAndSelfPickup",
        });
      }
    });

    let updatedFields = [...allFields];
    const fulfillmentOptionIndex = allFields.findIndex(
      (field) => field.id === "fulfillmentOption"
    );
    if (fulfillmentOptionIndex !== -1) {
      updatedFields[fulfillmentOptionIndex].options = availableOptions;

      setAllFields(updatedFields);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (state?.productId) {
      getProduct();
    }
    const user = JSON.parse(localStorage.getItem("user"));
    getOrgDetails(user.organization);
  }, []);
  console.log("Form Values", formValues);

  //   useEffect(() => {
  //     if (formValues?.productCategory) {
  //       let data = [...allFields]; // Create a copy of the fields array
  //       const subCategoryIndex = data.findIndex((item) => item.id === "productSubcategory1");
  //       data[subCategoryIndex].options = PRODUCT_SUBCATEGORY[formValues?.productCategory];

  //       const vegetarianIndex = data.findIndex((item) => item.id === "isVegetarian");
  //       if (formValues.productCategory === "f_and_b") {
  //         const imagesIndex = data.findIndex((item) => item.id === "images");
  //         data[imagesIndex].required = false;
  //         if (vegetarianIndex !== -1) {
  //           data[vegetarianIndex].required = true;
  //         }
  //       } else {
  //         const imagesIndex = data.findIndex((item) => item.id === "images");
  //         data[imagesIndex].required = true;
  //         if (vegetarianIndex !== -1) {
  //           data[vegetarianIndex].required = false;
  //         }
  //       }

  //       setAllFields(data);
  //     }
  //   }, [formValues]);
  const gender = useMemo(() => {
    if (state?.jewelleryCategory) {
      return state?.jewelleryCategory === "men" ? "MALE" : "FEMALE";
    } else {
      return jewelleryCategory === "men" ? "MALE" : "FEMALE";
    }
  }, [state, jewelleryCategory]);

  const color = useMemo(() => {
    if (state?.jewelleryType) {
      return state?.jewelleryType === "diamond" ||
        state?.jewelleryType === "gold"
        ? "#FFD700"
        : "#C0C0C0";
    } else {
      return jewelleryType === "diamond" || jewelleryType === "gold"
        ? "#FFD700"
        : "#C0C0C0";
    }
  }, [state, jewelleryType]);
  const baseMetal = useMemo(() => {
    if (state?.jewelleryType) {
      return state?.jewelleryType === "diamond" ||
        state?.jewelleryType === "gold"
        ? "GOLD"
        : "SILVER";
    } else {
      return jewelleryType === "diamond" || jewelleryType === "gold"
        ? "GOLD"
        : "SILVER";
    }
  }, [state, jewelleryType]);
  const platingValue = useMemo(() => {
    return ["yellow", "rose", "white"].includes(formValues.Finishing)
      ? "GOLD"
      : "SILVER";
  }, [formValues.Finishing]);


  useEffect(() => {
    let updatedVitalForm = {}
    updatedVitalForm["Gender"] = gender;
    updatedVitalForm["Colour"] = color;
    updatedVitalForm["Base Metal"] = baseMetal;
    updatedVitalForm["Plating"] = platingValue;

    setVitalForm((prev) => ({
      ...prev,
      ...updatedVitalForm,
    }));
    // console.log("updatedVitalForm : ", updatedVitalForm);

  }, [formValues.Finishing])

  useEffect(() => {
    setProductInfoFields(getProductInfoFields());

    // Set vital form data
    let vital_fields = attributes.filter(
      (variant) => !selectedVariantNames.includes(variant.name)
    );
    vital_fields = formatAttributesToFieldsDataFormat(vital_fields);
    //vital_fields.map((field) => {
    //   return {
    //     id: field.name,
    //     title: field.name,
    //     placeholder: "Example, " + field.example,
    //     type: field.type,
    //     required: true,
    //   };
    // });
    console.log("Vital Fields", [...ecomFieldsDetails, ...vital_fields]);
    setVitalFields([...ecomFieldsDetails, ...vital_fields]);

    let initial_values = vital_fields.reduce((acc, field) => {
      if (field.id === "Gender") {
        acc[field.id] = gender;
      } else if (field.id === "Colour") {
        acc[field.id] = color;
      } else if (field.id === "Base Metal") {
        acc[field.id] = baseMetal;
      } else if (field.id === "Plating") {
        acc[field.id] = platingValue;
      } else {
        acc[field.id] = "";
      }
      return acc;
    }, {});
    setVitalForm(initial_values);

    // Set variant form data
    let default_variant_fields = variationCommonFields.map((field_id) =>
      getProductFieldDetails(field_id)
    );

    if (variationOn !== "none") {
      let variants_fields = getVariantsFields();
      // let selected_variants = variants.filter((variant) =>
      //   selectedVariantNames.includes(variant.name)
      // );
      // let formatted_variants =
      //   formatAttributesToFieldsDataFormat(selected_variants);
      let all_variant_fields = [...variants_fields, ...default_variant_fields];
      let variant_initial_values = all_variant_fields.reduce((acc, field) => {
        acc[field.id] = field.id === "images" ? [] : "";
        return acc;
      }, {});
      setVariantFields(all_variant_fields);
      setVariantInitialValues(variant_initial_values);
      setVariantForms([{ ...variant_initial_values, formKey: uuidv4() }]);
    }
    // Set initial values for vital and variant tab
    let variant_tab_error = true;
    let vital_tab_error = true;

    if (variationOn === "none") {
      variant_tab_error = false;
    }

    if (Object.keys(vital_fields).length === 0) {
      vital_tab_error = false;
    }

    setTabErrors((prevState) => {
      prevState[2] = vital_tab_error;
      prevState[3] = variant_tab_error;
      return prevState;
    });
  }, []);
  // }, [variationOn, formValues.Finishing]);

  const getProductInfoFields = () => {
    let product_info_fields = [...productDetailsFields];
    let p_category = state?.productId ? state?.productCategory : category;
    let p_sub_category = state?.productId
      ? state?.productSubCategory
      : subCategory;
    let protocolKey = PRODUCT_SUBCATEGORY[p_category]?.filter(
      (sub_category) => sub_category.value === p_sub_category
    )[0].protocolKey;

    if (protocolKey && protocolKey !== "") {
      let fields_to_remove =
        FIELD_NOT_ALLOWED_BASED_ON_PROTOCOL_KEY[protocolKey];
      product_info_fields = product_info_fields.filter(
        (field) => !fields_to_remove.includes(field)
      );
    }

    if (category !== "Grocery" && category !== "F&B") {
      product_info_fields = product_info_fields.filter(
        (field) => field !== "vegNonVeg"
      );
    }
    if (state) {
      if (state?.jewelleryType !== "diamond") {
        product_info_fields = product_info_fields.filter(
          (field) =>
            ![
              "diamonds_weight",
              "diamondColour",
              "diamondClarity",
              "certifications",
              //
              "diamondCostPerCt",
              // "makingCharges",
            ].includes(field)
        );
      }
    }

    if (jewelleryType) {
      if (jewelleryType !== "diamond") {
        product_info_fields = product_info_fields.filter(
          (field) =>
            ![
              "diamonds_weight",
              "diamondColour",
              "diamondClarity",
              "certifications",
              //
              "diamondCostPerCt",
              // "makingCharges",
            ].includes(field)
        );
      }
    }

    if (!variationOn || variationOn === "none") {
      return [
        ...product_info_fields,
        ...UOMVariationFields,
        ...variationCommonFields,
      ];
    } else if (variationOn === "attributes") {
      return [...product_info_fields, ...UOMVariationFields];
    } else {
      return product_info_fields;
    }
  };

  const getVariantsFields = () => {
    if (!variationOn || variationOn === "none") {
      return [];
    } else if (variationOn === "attributes") {
      let selected_variants = variants.filter((variant) =>
        selectedVariantNames.includes(variant.name)
      );
      return formatAttributesToFieldsDataFormat(selected_variants, true);
    } else if (variationOn === "uom") {
      return UOMVariationFields.map((field_id) =>
        getProductFieldDetails(field_id)
      );
    }
  };

  const validateProductInfoForm = () => {
    let formErrors = {};
    formErrors.productCode =
      formValues?.productCode?.trim() === ""
        ? "Product code is not allowed to be empty"
        : !isNumberOnly(formValues?.productCode)
          ? "Please enter only digit"
          : formValues?.productCode?.length > MAX_STRING_LENGTH_13
            ? `Cannot be more than ${MAX_STRING_LENGTH_13} characters`
            : "";
    formErrors.productName =
      formValues?.productName?.trim() === ""
        ? "Product name is not allowed to be empty"
        : formValues?.productName?.length > MAX_STRING_LENGTH
          ? `Cannot be more than ${MAX_STRING_LENGTH} characters`
          : "";
    // formErrors.HSNCode =
    //   formValues?.HSNCode?.trim() === ""
    //     ? "HSN code is not allowed to be empty"
    //     : formValues?.HSNCode?.length > MAX_STRING_LENGTH_8
    //     ? `Cannot be more than ${MAX_STRING_LENGTH_8} characters`
    //     : "";
    // formErrors.huidNumber =
    //   formValues?.huidNumber?.trim() === "0"
    //     ? "HUID shoud be done."
    //     : formValues?.huidNumber?.trim() === ""
    //     ? `This field shoud not be empty`
    //     : "";

    formErrors.huidDone =
      formValues?.huidDone?.trim() === "false"
        ? "HUID shoud be done."
        : formValues?.huidDone?.trim() === ""
          ? "HUID Done is not allowed to be empty"
          : "";
    // formErrors.countryOfOrigin =
    //   formValues?.countryOfOrigin?.trim() === ""
    //     ? "Country of origin is not allowed to be empty"
    //     : "";
    formErrors.Finishing =
      formValues?.Finishing?.trim() === ""
        ? "Finishing is not allowed to be empty"
        : "";
    formErrors.GST_Percentage =
      formValues?.GST_Percentage === "" ? "GST percentage is required" : "";
    // formErrors.maxAllowedQty = !formValues?.maxAllowedQty
    //   ? "Please enter a valid Max. Allowed Quantity"
    //   : formValues?.maxAllowedQty?.length > MAX_STRING_LENGTH_10
    //   ? `Cannot be more than ${MAX_STRING_LENGTH_10} characters`
    //   : parseInt(formValues?.maxAllowedQty) > parseInt(formValues?.quantity)
    //   ? "Cannot be more than quantity"
    //   : "";
    // formErrors.UOM = formValues?.UOM === "" ? "UOM unit is required" : "";
    // formErrors.fulfillmentOption =
    //   formValues?.fulfillmentOption === undefined ||
    //   formValues?.fulfillmentOption === ""
    //     ? "Fulfillment Option is required"
    //     : "";
    // formErrors.UOM =
    //   formValues?.UOM?.trim() === ""
    //     ? "UOM is required"

    //     : formValues?.UOM?.length > MAX_STRING_LENGTH
    //     ? `Cannot be more than ${MAX_STRING_LENGTH} characters`
    //     : "";
    // formErrors.length =
    //   formValues?.length?.trim() === ""
    //     ? "Length is required"
    //     : formValues?.length.length > MAX_STRING_LENGTH_6
    //     ? `Cannot be more than ${MAX_STRING_LENGTH_6} characters`
    //     : "";
    // formErrors.breadth =
    //   formValues?.breadth?.trim() === ""
    //     ? "Breadth is required"
    //     : formValues?.breadth.length > MAX_STRING_LENGTH_6
    //     ? `Cannot be more than ${MAX_STRING_LENGTH_6} characters`
    //     : "";
    // formErrors.height =
    //   formValues?.height?.trim() === ""
    //     ? "Height is required"
    //     : formValues?.height.length > MAX_STRING_LENGTH_6
    //     ? `Cannot be more than ${MAX_STRING_LENGTH_6} characters`
    //     : "";
    formErrors.weight =
      formValues?.weight?.trim() === ""
        ? "Gross Weight is required"
        : formValues?.weight.length > MAX_STRING_LENGTH_8
          ? `Cannot be more than ${MAX_STRING_LENGTH_8} characters`
          : "";
    formErrors.net_weight =
      formValues?.net_weight?.trim() === ""
        ? "Net Weight is required"
        : formValues?.weight.length > MAX_STRING_LENGTH_8
          ? `Cannot be more than ${MAX_STRING_LENGTH_8} characters`
          : "";
    if (productInfoFields.includes("diamonds_weight")) {
      formErrors.diamonds_weight =
        formValues?.diamonds_weight == ""
          ? "Diamonds Weight is required"
          : formValues?.diamonds_weight.length > MAX_STRING_LENGTH_8
            ? `Cannot be more than ${MAX_STRING_LENGTH_8} characters`
            : "";
    }
    if (productInfoFields.includes("diamondColour")) {
      formErrors.diamondColour =
        formValues?.diamondColour?.trim() === ""
          ? "Diamond Colour is required"
          : formValues?.diamondColour.length > MAX_STRING_LENGTH_3
            ? `Cannot be more than ${MAX_STRING_LENGTH_3} characters`
            : "";
    }
    if (productInfoFields.includes("diamondClarity")) {
      formErrors.diamondClarity =
        formValues?.diamondClarity?.trim() === ""
          ? "Diamond Clarity is required"
          : formValues?.diamondClarity.length > MAX_STRING_LENGTH_6
            ? `Cannot be more than ${MAX_STRING_LENGTH_6} characters`
            : "";
    }
    if (productInfoFields.includes("certifications")) {
      formErrors.certifications =
        formValues?.certifications !== "f_and_b" &&
          formValues?.images.length < 1
          ? "Certification Image is required"
          : "";
    }
    if (productInfoFields.includes("diamondCostPerCt")) {
      formErrors.diamondCostPerCt = !formValues?.diamondCostPerCt
        ? "Please enter a valid number"
        : !isAmountValid(formValues?.diamondCostPerCt)
          ? "Please enter only digit"
          : "";
    }
    if (productInfoFields.includes("makingCharges")) {
      formErrors.makingCharges = !formValues?.makingCharges
        ? "Please enter a valid number"
        : !isAmountValid(formValues?.makingCharges)
          ? "Please enter only digit"
          : "";
    }
    formErrors.totalSellingPrice = !formValues?.totalSellingPrice
      ? "Total Selling Price is Required "
      : !isAmountValid(formValues?.totalSellingPrice)
        ? "Please enter only digit"
        : "";
    formErrors.sellerPrice = !formValues?.sellerPrice
      ? "Total Selling Price is Required "
      : !isAmountValid(formValues?.sellerPrice)
        ? "Please enter only digit"
        : "";
    formErrors.Purity =
      formValues?.Purity?.trim() === "" ? "Purity is required" : "";
    // formErrors.dispatchTime =
    //   formValues?.dispatchTime?.trim() === ""
    //     ? "Dispatch Time is required"
    //     : !isNumberOnly(formValues?.returnWindow)
    //     ? "Please enter only digit"
    //     : formValues?.returnWindow?.length > MAX_STRING_LENGTH_3
    //     ? `Cannot be more than ${MAX_STRING_LENGTH_3} characters`
    //     : "";
    formErrors.wastageOnGold =
      formValues?.wastageOnGold === ""
        ? "Please Enter Wastage of Gold Value (in %)"
        : formValues?.wastageOnGold > 100
          ? "Wastage on Gold Value (in %) should be less that 100"
          : "";
    // formErrors.returnWindow =
    //   formValues?.returnWindow?.trim() === ""
    //     ? "Return window is required"
    //     : !isNumberOnly(formValues?.returnWindow)
    //     ? "Please enter only digit"
    //     : formValues?.returnWindow?.length > MAX_STRING_LENGTH_3
    //     ? `Cannot be more than ${MAX_STRING_LENGTH_3} characters`
    //     : "";
    // formErrors.manufacturerName =
    //   formValues?.manufacturerName?.trim() === ""
    //     ? "Manufacturer name is required"
    //     : formValues?.manufacturerName?.length > MAX_STRING_LENGTH_50
    //     ? `Cannot be more than ${MAX_STRING_LENGTH_50} characters`
    //     : "";
    // formErrors.manufacturedDate =
    //   formValues?.manufacturedDate?.trim() === ""
    //     ? "Manufactured date is required"
    //     : "";
    // if (productInfoFields.includes("nutritionalInfo")) {
    //   formErrors.nutritionalInfo =
    //     formValues?.nutritionalInfo?.trim() === ""
    //       ? "Nutritional info is required"
    //       : formValues?.nutritionalInfo?.length > MAX_STRING_LENGTH
    //       ? `Cannot be more than ${MAX_STRING_LENGTH} characters`
    //       : "";
    // }
    // if (productInfoFields.includes("additiveInfo")) {
    //   formErrors.additiveInfo =
    //     formValues?.additiveInfo?.trim() === ""
    //       ? "Additive info is required"
    //       : formValues?.additiveInfo?.length > MAX_STRING_LENGTH
    //       ? `Cannot be more than ${MAX_STRING_LENGTH} characters`
    //       : "";
    // }
    // formErrors.instructions =
    //   formValues?.instructions?.trim() === ""
    //     ? "Instruction is required"
    //     : formValues?.instructions?.length > MAX_STRING_LENGTH
    //     ? `Cannot be more than ${MAX_STRING_LENGTH} characters`
    //     : "";
    // formErrors.longDescription =
    //   formValues?.longDescription?.trim() === ""
    //     ? "Long description is required"
    //     : formValues?.longDescription?.length > MAX_STRING_LENGTH
    //     ? `Cannot be more than ${MAX_STRING_LENGTH} characters`
    //     : "";
    formErrors.description =
      formValues?.description?.trim() === ""
        ? "Short description is required"
        : formValues?.description?.length > MAX_STRING_LENGTH
          ? `Cannot be more than ${MAX_STRING_LENGTH} characters`
          : "";
    // if (productInfoFields.includes("manufacturerOrPackerName")) {
    //   formErrors.manufacturerOrPackerName =
    //     formValues?.manufacturerOrPackerName?.trim() === ""
    //       ? "Manufacturer or packer name is required"
    //       : formValues?.manufacturerOrPackerName?.length > MAX_STRING_LENGTH_50
    //       ? `Cannot be more than ${MAX_STRING_LENGTH_50} characters`
    //       : "";
    // }
    // if (productInfoFields.includes("manufacturerOrPackerAddress")) {
    //   formErrors.manufacturerOrPackerAddress =
    //     formValues?.manufacturerOrPackerAddress?.trim() === ""
    //       ? "Manufacturer or packer address is required"
    //       : formValues?.manufacturerOrPackerAddress?.length >
    //         MAX_STRING_LENGTH_100
    //       ? `Cannot be more than ${MAX_STRING_LENGTH_100} characters`
    //       : "";
    // }
    // if (productInfoFields.includes("commonOrGenericNameOfCommodity")) {
    //   formErrors.commonOrGenericNameOfCommodity =
    //     formValues?.commonOrGenericNameOfCommodity?.trim() === ""
    //       ? "Common or generic name of commodity is required"
    //       : formValues?.commonOrGenericNameOfCommodity?.length >
    //         MAX_STRING_LENGTH_50
    //       ? `Cannot be more than ${MAX_STRING_LENGTH_50} characters`
    //       : "";
    // }
    // if (productInfoFields.includes("monthYearOfManufacturePackingImport")) {
    //   formErrors.monthYearOfManufacturePackingImport =
    //     formValues?.monthYearOfManufacturePackingImport?.trim() === ""
    //       ? "Month year of manufacture packing import is required"
    //       : formValues?.monthYearOfManufacturePackingImport?.length >
    //         MAX_STRING_LENGTH
    //       ? `Cannot be more than ${MAX_STRING_LENGTH} characters`
    //       : "";
    // }
    // if (productInfoFields.includes("importerFSSAILicenseNo")) {
    //   formErrors.importerFSSAILicenseNo =
    //     formValues?.importerFSSAILicenseNo?.trim() === ""
    //       ? "Importer FSSAI license no is required"
    //       : !isNumberOnly(formValues?.importerFSSAILicenseNo)
    //       ? "Please enter only digit"
    //       : formValues?.importerFSSAILicenseNo?.length > MAX_STRING_LENGTH_14
    //       ? `Cannot be more than ${MAX_STRING_LENGTH_14} characters`
    //       : "";
    // }
    // if (productInfoFields.includes("brandOwnerFSSAILicenseNo")) {
    //   formErrors.brandOwnerFSSAILicenseNo =
    //     formValues?.brandOwnerFSSAILicenseNo?.trim() === ""
    //       ? "Brand owner FSSAI license no is required"
    //       : !isNumberOnly(formValues?.brandOwnerFSSAILicenseNo)
    //       ? "Please enter only digit"
    //       : formValues?.brandOwnerFSSAILicenseNo?.length > MAX_STRING_LENGTH_14
    //       ? `Cannot be more than ${MAX_STRING_LENGTH_14} characters`
    //       : "";
    // }
    if (variationOn === "none") {
      // formErrors.MRP = !formValues?.MRP
      //   ? "Please enter a valid number"
      //   : !isAmountValid(formValues?.MRP)
      //   ? "Please enter only digit"
      //   : "";
      // formErrors.purchasePrice = !formValues?.purchasePrice
      //   ? "Please enter a valid number"
      //   : !isAmountValid(formValues?.purchasePrice)
      //   ? "Please enter only digit"
      //   : "";
      formErrors.quantity = !formValues?.quantity
        ? "Please enter a valid Quantity"
        : !isNumberOnly(formValues?.quantity)
          ? "Please enter only digit"
          : "";
      // formErrors.barcode = !formValues?.barcode
      //   ? "Please enter a valid Barcode"
      //   : !isNumberOnly(formValues?.barcode)
      //   ? "Please enter only digit"
      //   : formValues?.barcode?.length > MAX_STRING_LENGTH_12
      //   ? `Cannot be more than ${MAX_STRING_LENGTH_12} characters`
      //   : "";
      formErrors.images =
        formValues?.productCategory !== "f_and_b" &&
          formValues?.images.length < 4
          ? "Minimum 4 images are required"
          : "";
    }


    if (formValues?.productCategory) {
      const subCatList = PRODUCT_SUBCATEGORY[formValues?.productCategory];
      const selectedSubCatObject = subCatList?.find(
        (subitem) => subitem.value === formValues?.productSubcategory1
      );
      if (selectedSubCatObject && selectedSubCatObject.protocolKey) {
        const hiddenFields =
          FIELD_NOT_ALLOWED_BASED_ON_PROTOCOL_KEY[
          selectedSubCatObject.protocolKey
          ];
        hiddenFields?.forEach((field) => {
          formErrors[field] = "";
        });
      } else {
      }
    } else {
    }

    console.log("Product form errors : ", formErrors)

    setErrors({
      ...formErrors,
    });


    let valid_form = !Object.values(formErrors).some((val) => val !== "");
    return valid_form;
  };

  const validateVitalInfoForm = () => {
    // if (Object.keys(vitalFields).length === 0) {
    //   return true;
    // } else {
    let form_errors = getFormErrors(vitalFields, vitalForm);
    form_errors.manufacturerOrPackerAddress =
      vitalForm?.manufacturerOrPackerAddress?.trim() === ""
        ? "Manufacturer or packer address is required"
        : vitalForm?.manufacturerOrPackerAddress.length >
          MAX_STRING_LENGTH_100
          ? `Cannot be more than ${MAX_STRING_LENGTH_100} characters`
          : "";

    let is_valid_form = form_errors
      ? !Object.values(form_errors).some((val) => val !== "")
      : true;
    setVitalFormErrors(form_errors);
    return is_valid_form;
    // }
  };


  const validateDeliveryInfoForm = () => {
    if (Object.keys(deliveryFields).length === 0) {
      return true;
    } else {
      let form_errors = getFormErrors(deliveryFields, deliveryForm);
      let is_valid_form = form_errors
        ? !Object.values(form_errors).some((val) => val !== "")
        : true;
      setDeliveryFormErrors(form_errors);
      return is_valid_form;
    }
  };

  const validateVariantsForms = () => {
    if (variationOn === "none") {
      return true;
    } else {
      let forms_errors = variantForms.map((variant_form) =>
        getFormErrors(variantFields, variant_form)
      );
      let has_forms_errors = forms_errors.map((form_errors) =>
        Object.values(form_errors).some((val) => val !== "")
      );
      let are_valid_forms = !has_forms_errors.some((val) => val === true);
      setVariantFormsErrors(forms_errors);
      return are_valid_forms;
    }
  };

  const validateCustomizationDetails = () => {
    const getCustomizationGroupName = (groupId) => {
      const group = customizationGroups.find((group) => group.id === groupId);
      return group ? group.name : "";
    };

    const getCustomizationName = (customizationId) => {
      const customization = customizations.find(
        (customization) => customization.id === customizationId
      );
      return customization ? customization.name : "";
    };

    const selectedCustomizations = customizations.filter(
      (customization) => customization.parent
    );

    if (customizationGroups.length > 0) {
      // Validation check: If customization groups are present, check that all groups have at least one customization.
      const groupIdsWithCustomizations = new Set(
        selectedCustomizations.map((customization) => customization.parent)
      );
      const groupIds = new Set(customizationGroups.map((group) => group.id));

      if (groupIdsWithCustomizations.size < groupIds.size) {
        const missingGroups = [...groupIds].filter(
          (groupId) => !groupIdsWithCustomizations.has(groupId)
        );
        const missingGroupNames = missingGroups.map((groupId) =>
          getCustomizationGroupName(groupId)
        );
        cogoToast.error(
          `Please add at least one customization for groups: ${missingGroupNames.join(
            ", "
          )}.`
        );
        return false;
      }
    }

    // Validation check: If any customization has no child property, it must have a price value greater than 0.
    const invalidCustomizations = selectedCustomizations.filter(
      (customization) =>
        !customization.child &&
        (!customization.price ||
          (customization.price <= 0 && customization.default === "false"))
    );

    if (invalidCustomizations.length > 0) {
      const errorMessages = invalidCustomizations.map((customization) => {
        const groupName = getCustomizationGroupName(customization.parent);
        const customizationName = getCustomizationName(customization.id);
        return `${groupName} [${customizationName}]`;
      });

      cogoToast.error(
        `Customizations with the following details must have a price greater than 0: ${errorMessages.join(
          ", "
        )}.`
      );
      return false;
    }

    return true;
  };

  const validate = () => {
    // console.log("At validate.")
    let product_info_form_validity = validateProductInfoForm();
    let delivery_info_form_validity = validateDeliveryInfoForm();
    let vital_info_form_validity = validateVitalInfoForm();
    let variants_forms_validity = validateVariantsForms();
    // let customization_details_validity = validateCustomizationDetails();

    setTabErrors((prev_state) => {
      prev_state[0] = !product_info_form_validity;
      prev_state[1] = !delivery_info_form_validity;
      prev_state[2] = !vital_info_form_validity;
      prev_state[3] = !variants_forms_validity;
      // prev_state[3] = !customization_details_validity;
      return [...prev_state];
    });

    console.log("At validate Tab errors : ", product_info_form_validity ,"and",delivery_info_form_validity ,"and", vital_info_form_validity,"and", variants_forms_validity );

    let result =
      variants_forms_validity &&
      product_info_form_validity && vital_info_form_validity && delivery_info_form_validity;
    // customization_details_validity;

    // console.log("At validate result : ", result);
    return result;
  };

  const validateUpdation = () => {
    let product_info_form_validity = validateProductInfoForm();
    let delivery_info_form_validity = validateDeliveryInfoForm();
    let vital_info_form_validity = validateVitalInfoForm();

    // let variants_forms_validity = validateVariantsForms();
    // let customization_details_validity = validateCustomizationDetails();

    setTabErrors((prev_state) => {
      prev_state[0] = !product_info_form_validity;
      prev_state[1] = !delivery_info_form_validity;
      prev_state[2] = !vital_info_form_validity;
      // prev_state[3] = !variants_forms_validity;
      // prev_state[3] = !customization_details_validity;
      return [...prev_state];
    });

    console.log("At validateUpdation Tab errors : ", product_info_form_validity ,"and",delivery_info_form_validity ,"and", vital_info_form_validity);

    let result =
      // variants_forms_validity &&
      product_info_form_validity && vital_info_form_validity && delivery_info_form_validity;
    // customization_details_validity;

    console.log("At validateUpdation result : ", result);
    return result;
  };

  const handleSubmit = () => {
    if (state?.productId) {
      if (validateUpdation()) {
        updateProduct();
      }
    } else {
      if (validate()) {
        addProduct();
      }
    }
  };

  const renderVariationsFields = () => {
    return (
      <AddVariants
        variantFields={variantFields}
        variantInitialValues={variantInitialValues}
        variantForms={variantForms}
        setVariantForms={setVariantForms}
        variantFormsErrors={variantFormsErrors}
      />
    );
  };

  const renderProductInfoFields = () => {
    return (
      <AddProductInfo
        allFields={allFields}
        fields={productInfoFields}
        category={category}
        subCategory={subCategory}
        state={state}
        form={productInfoForm}
        setFocusedField={setFocusedField}
      />
    );
  };

  const renderProductVitalFields = () => {
    return (
      <VitalForm
        fields={vitalFields}
        formData={vitalForm}
        onFormUpdate={setVitalForm}
        vitalFormErrors={vitalFormErrors}
      />
    );
  };


  const renderProductDeliveryFields = () => {
    return (
      <VitalForm
        fields={deliveryFields}
        formData={deliveryForm}
        onFormUpdate={setDeliveryForm}
        vitalFormErrors={deliveryFormErrors}
      />
    );
  };

  const renderCustomizations = () => {
    return (
      <CustomizationRenderer
        category={category}
        customizationGroups={customizationGroups}
        setCustomizationGroups={setCustomizationGroups}
        customizations={customizations}
        setCustomizations={setCustomizations}
      />
    );
  };

  // useEffect(() => {
  //   if (!formValidate) {
  //     let formErrors = {};
  //     let focusFieldValue = formValues[focusedField]?.toString().trim();
  //     if (
  //       focusFieldValue !== "" &&
  //       focusedField === "manufacturerOrPackerName"
  //     ) {
  //       formErrors.manufacturerOrPackerName =
  //         formValues?.manufacturerOrPackerName?.trim() === ""
  //           ? "Manufacturer or packer name is required"
  //           : formValues?.manufacturerOrPackerName.length > MAX_STRING_LENGTH_50
  //           ? `Cannot be more than ${MAX_STRING_LENGTH_50} characters`
  //           : "";
  //     } else if (
  //       focusFieldValue !== "" &&
  //       focusedField === "manufacturerOrPackerAddress"
  //     ) {
  //       formErrors.manufacturerOrPackerAddress =
  //         formValues?.manufacturerOrPackerAddress?.trim() === ""
  //           ? "Manufacturer or packer address is required"
  //           : formValues?.manufacturerOrPackerAddress.length >
  //             MAX_STRING_LENGTH_100
  //           ? `Cannot be more than ${MAX_STRING_LENGTH_100} characters`
  //           : "";
  //     } else if (
  //       focusFieldValue !== "" &&
  //       focusedField === "commonOrGenericNameOfCommodity"
  //     ) {
  //       formErrors.description =
  //         formValues?.description?.trim() === ""
  //           ? "Short description cannot be empty"
  //           : formValues?.commonOrGenericNameOfCommodity.length >
  //             MAX_STRING_LENGTH_50
  //           ? `Cannot be more than ${MAX_STRING_LENGTH_50} characters`
  //           : "";
  //     } else if (focusFieldValue !== "" && focusedField === "description") {
  //       formErrors.description =
  //         formValues?.description?.trim() === ""
  //           ? "Short description is required"
  //           : formValues?.description?.length > MAX_STRING_LENGTH
  //           ? `Cannot be more than ${MAX_STRING_LENGTH} characters`
  //           : "";
  //     }
  //     setErrors((prevErrors) => ({
  //       ...prevErrors,
  //       ...formErrors,
  //     }));
  //   } else {
  //     if (state?.productId) {
  //       return;
  //     }
  //     validate();
  //   }
  // }, [formValues, focusedField]);

  let highlightedTabColor = tabErrors.includes(true) ? "error" : "primary";

  return (
    <form>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleTabChange}
              textColor={highlightedTabColor}
              centered
            >
              <Tab
                sx={{
                  color:
                    tabErrors[0] && Object.keys(errors).length > 0
                      ? "red"
                      : "none",
                  minWidth: "150px",
                }}
                label="Product Info"
                value="1"
              // textColor={tabErrors[0] ? "error" : "none"}
              // indicatorColor="secondary"
              />
              {Object.keys(deliveryFields).length > 0 && (
                <Tab
                  sx={{
                    color:
                      tabErrors[1] && Object.keys(errors).length > 0
                        ? "red"
                        : "none",
                    minWidth: "150px",
                  }}
                  label="Delivery Info"
                  value="2"
                />
              )}
              {Object.keys(vitalFields).length > 0 && (
                <Tab
                  sx={{
                    color:
                      tabErrors[2] && Object.keys(errors).length > 0
                        ? "red"
                        : "none",
                    minWidth: "150px",
                  }}
                  label="Vital Info"
                  value="3"
                />
              )}
              {variationOn !== "none" && (
                <Tab
                  sx={{
                    color:
                      tabErrors[3] && Object.keys(errors).length > 0
                        ? "red"
                        : "none",
                    minWidth: "150px",
                  }}
                  label="Variations"
                  value="4"
                />
              )}
              {/* <Tab
                sx={{
                  color:
                    tabErrors[3] && Object.keys(errors).length > 0
                      ? "red"
                      : "none",
                }}
                label="Delivery Info"
                value="4"
              /> */}
            </TabList>
          </Box>
          <TabPanel value="1">
            <div className="mt-2">{renderProductInfoFields()}</div>
          </TabPanel>
          <TabPanel value="2">
            <div className="mt-2">{renderProductDeliveryFields()}</div>
          </TabPanel>
          <TabPanel value="3">
            <div className="mt-2">{renderProductVitalFields()}</div>
          </TabPanel>
         <TabPanel value="4">{renderVariationsFields()}</TabPanel>
          {/* <TabPanel value="4">{renderDelivery()}</TabPanel>  */}
        </TabContext>
      </Box>

      <div className="flex flex-row justify-center py-2">
        <MyButton
          type="button"
          title={state?.productId ? "Update Product" : "ADD PRODUCT"}
          variant="contained"
          className="!bg-[#ebe4dc] !text-black"
          onClick={handleSubmit}
        />
        {loading && <CircularProgress />}
      </div>
    </form>
  );
};

export default AddGenericProduct;
