import { useState, Fragment, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import cogoToast from "cogo-toast";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { getCall, postCall, putCall } from "../../../Api/axios";
import { useTheme } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)({
  "&.MuiTableCell-root": {
    fontWeight: "bold",
  },
});
const StyledMoreVertIcon = styled(MoreVertIcon)(({theme})=>({
  color : theme.palette.tertiary.main
}));

export default function InventoryTableSeller(props) {
  const {
    page,
    rowsPerPage,
    totalRecords,
    handlePageChange,
    handleRowsPerPageChange,
    onRefresh,
    user,
    getProducts,
  } = props;
  const theme = useTheme();
  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);

  const onPageChange = (event, newPage) => {
    handlePageChange(newPage);
  };
  const [rows, setRows] = useState([]);

  // Function to update row data
  const onUpdateRow = (updatedRow) => {
    // Find the index of the updated row
    const rowIndex = rows.findIndex((row) => row.id === updatedRow.id);
    // Create a new array with the updated row data
    const updatedRows = [...rows];
    updatedRows[rowIndex] = updatedRow;
    // Update the state with the new array of rows
    setRows(updatedRows);
  };
  const onRowsPerPageChange = (event) => {
    handleRowsPerPageChange(parseInt(event.target.value, 10));
    handlePageChange(0);
  };

  const handleApproveQC = (product_id, newQCStatus) => {
    const url = `/api/v1/products/GetDataForQC/${product_id}`;

    // Step 1: Fetch data from the first API
    getCall(url)
      .then((resp) => {
        // Step 2: Manipulate the received data
        let data = {
          commonDetails: resp.commonDetails,
          commonAttributesValues: resp.commonAttributesValues,
        };
        if (resp.commonDetails.QCStatus === "Approved") {
          cogoToast.success("The product is Already Approved");
          return;
        }
        console.log("Here is the Data");
        console.dir(data,{depth:null});
        const fieldMapping = {
          totalSellingPrice: "MRP",
          sellerPrice:"sellerPrice",
          sellerPrice:"purchasePrice",
          10:"maxAllowedQty",
          _id:"relation",


        };
        console.log("Here is the fieldMapping",fieldMapping);
        const fieldsToRemove = [
          "_id",
          "__v",
          "published",
          "updatedAt",
          "QCStatus",
          "QCDesc",
          "huidNumber",
          "huidDone",
          "Finishing",
          "wastageOnGold",
          "net_weight",
          "diamonds_weight",
          "diamondColour",
          "diamondClarity",
          "diamondPrice",
          "Purity",
          "diamondCostPerCt",
          "makingCharges",
          "certifications",
          "gemstones",
          "gemstonePrice",
          "productSubcategory2",
          "productSubcategory3",
        ];
        const id = data.commonDetails._id;
        Object.keys(fieldMapping).forEach((oldField) => {
          const newField = fieldMapping[oldField];
          if (data.commonDetails.hasOwnProperty(oldField)) {
            data.commonDetails[newField] = data.commonDetails[oldField];
            delete data.commonDetails[oldField];
          }
        });
        console.log("Here is the data before removing");
        console.dir(data,{depth:null});
        fieldsToRemove.forEach((field) => {
          delete data.commonDetails[field];
        });
        console.log("Here is the data after removing");
        console.dir(data,{depth:null});

        // Step 3: Send manipulated data to the second API
        let data2 = {
          QCStatus: "Approved",
          QCDesc: "Approved",
        };
        postCall(`/api/v1/products?_id=${id}`, data).then((resp) => {
          cogoToast.success("Product Pushed to Main DB!");
          putCall(`/api/v1/products/${product_id}/QC`, data2).then((resp) => {
            cogoToast.success("Product QC Status Updated");
            getProducts();
          });
        });
      })
      .catch((error) => {
        console.log(error);
        cogoToast.error(error.response.data.error);
      });
  };

  const handleRejectQC = (product_id, newQCStatus) => {
    setSelectedProduct(product_id);
    setOpenRejectDialog(true);
  };

  const handleCloseDialog = () => {
    setSelectedProduct(null);
    setOpenRejectDialog(false);
    setRejectReason("");
  };

  const handleSubmitRejectQC = () => {
    if (!rejectReason.trim()) {
      cogoToast.error("Please enter a valid reason for rejection");
      return;
    }
    const data = {
      QCStatus: "Rejected",
      QCDesc: rejectReason,
    };
    putCall(`/api/v1/products/${selectedProduct}/QC`, data)
      .then((resp) => {
        cogoToast.success("QC Rejected Successfully");
        getProducts();
        handleCloseDialog();
      })
      .catch((error) => {
        console.log(error);
        cogoToast.error(error.response.data.error);
      });
  };
  // useEffect(() => {
  //   // Fetch data for each row and update jewelleryType and jewelleryCategory states
  //   const fetchDataForRows = async () => {
  //     const updatedRows = await Promise.all(
  //       props.data.map(async (row) => {
  //         try {
  //           const resp = await getCall(`/api/v1/products/GetDataForQC/${row._id}`);
  //           const jewelleryType = resp?.commonDetails?.productSubcategory2 || "";
  //           const jewelleryCategory = resp?.commonDetails?.productSubcategory3 || "";
  //           return { ...row, jewelleryType, jewelleryCategory };
  //         } catch (error) {
  //           console.error(error);
  //           cogoToast.error(error.response.data.error);
  //           return row;
  //         }
  //       })
  //     );
  //     setRows(updatedRows);
  //   };

  //   fetchDataForRows();
  // }, [props.data]);

  const ThreeDotsMenu = ({ row }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (e) => {
      setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };
    const [jewelleryType, setJewelleryType] = useState("");
    const [jewelleryCategory, setJewelleryCategory] = useState("");
  
    // Effect to fetch data for jewelleryType and jewelleryCategory when row updates
    useEffect(() => {
      if (row) {
        getCall(`/api/v1/products/GetDataForQC/${row._id}`)
          .then((resp) => {
            const type = resp?.commonDetails?.productSubcategory2 || "";
            const category = resp?.commonDetails?.productSubcategory3 || "";
            setJewelleryType(type);
            setJewelleryCategory(category);
          })
          .catch((error) => {
            console.error(error);
            cogoToast.error(error.response?.data?.error || "Error fetching data");
          });
      }
    }, [row]);
    console.log("Jewellery type for ",row._id, " is ",jewelleryType);
    console.log("Jewellery Category for ",row._id, " is ",jewelleryCategory);

    return (
      <>
        <Tooltip title="Action">
          <Button onClick={handleClick}>
            <StyledMoreVertIcon />
          </Button>
        </Tooltip>
        <Menu
          id="card-actions-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <Link
            to="/application/add-products"
            state={{
              productId: row._id,
              productCategory: row.productCategory,
              productSubCategory: row.productSubcategory1,
              jewelleryType: jewelleryType,
              jewelleryCategory: jewelleryCategory,
            }}
          >
            <MenuItem>View & Update</MenuItem>
          </Link>
          {user.role.name === "Super Admin" &&
            row.QCStatus === "InProgress" && (
              <>
                <MenuItem
                  onClick={() => handleApproveQC(row._id, row.newQCStatus)}
                >
                  Approve QC
                </MenuItem>
                <MenuItem
                  onClick={() => handleRejectQC(row._id, row.newQCStatus)}
                >
                  Reject QC
                </MenuItem>
              </>
            )}
        </Menu>
      </>
    );
  };

  const renderCellContent = (column, value) => {
    if (typeof value === "boolean") {
      return (
        <div>
          <span className="ml-2">
            {value === false || value === null ? "No" : "Yes"}
          </span>
        </div>
      );
    } else {
      return column.format ? column.format(value) : value;
    }
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {props.columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                  }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
              <StyledTableCell
                style={{
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                }}
              >
                Action
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  {props.columns.map((column) => {
                    const value =
                      row[column.id] === undefined ? " - " : row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {renderCellContent(column, value)}
                      </TableCell>
                    );
                  })}
                  <TableCell component="th" scope="row">
                    <ThreeDotsMenu row={row} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
      <Dialog open={openRejectDialog} onClose={handleCloseDialog}>
        <DialogTitle>Enter reason for rejection</DialogTitle>
        <DialogContent>
          <input
            type="text"
            value={rejectReason}
            onChange={(e) => setRejectReason(e.target.value)}
            placeholder="Give Proper Reason for Rejection"
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleSubmitRejectQC}>Submit</Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}
